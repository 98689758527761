body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-flex {
  flex: 0 !important;
}

small {
  color: #b5b5c3;
  font-style: normal;
}

b {
  font-weight: 600;
}

th.table-header {
  font-style: normal;
  font-weight: 600;
  color: #b5b5c3;
}

button.save-btn {
  color: #6993ff;
  background-color: #e1e9ff;
  font-weight: 600;
}

button.save-btn:hover {
  color: #6993ff;
  background-color: #e1e9ff;
  font-weight: 600;
}

button.del-btn {
  color: #f64e60;
  background-color: #ffe2e5;
  font-weight: 600;
}

button.del-btn:hover {
  color: #f64e60;
  background-color: #ffe2e5;
  font-weight: 600;
}

svg.del-btn {
  color: #f64e60;
}

.DateInput__small {
  width: 48%;
}
.DateInput_input__small {
  text-align: center;
}
.DateRangePickerInput_arrow {
  width: 4%;
}

.schedule-cell-dimension.e-schedule
  .e-vertical-view
  .e-time-cells-wrap
  table
  td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
  height: 40px;
}

.MuiAlert-message {
  flex-direction: row !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 2px 2px 17px rgba(0, 0, 0, 0.35),
    2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media print {
  @page {
    size: A3;
  }
}
