.schedule-cell-dimension.e-schedule
  .e-vertical-view
  .e-time-cells-wrap
  table
  td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
  height: 30px;
}

.schedule-cell-dimension .e-subject {
  font-size: 10px !important;
}
